import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setActiveComponentId } from '../../bundles/application';
import { getTrail } from '../../bundles/trails';
import { getSystemMessages } from '../../bundles/systemMessages';
import { getActiveSite, setActiveSite, getSiteSetAt } from '../../bundles/sites';
import { getLayout } from '../../bundles/layouts';
import { getHasPermission } from '../../bundles/auth';

import Sidebar from '../Sidebar';
import PageHeader from '../PageHeader';
import Notifications from '../Notifications';
import UserInfo from '../UserInfo';
import HeaderSettings from '../PageHeader/HeaderSettings';
import ConnectivityStatus from '../PageHeader/ConnectivityStatus';
import PollingRefresh from '../PageHeader/PollingRefresh';

const BaseView = ({
  match: {
    params: { id: siteId, page },
  },
  withConnectivity = true,
  withSidebar = true,
  isTenantAdminView = false,
  withRefresh = false,
  noSiteView = false,
  siteAdminView = false,
  title,
  headerChildren,
  trail: staticTrail,
  className = '',
  withPadding = true,
  requiredPermission,
}) => {
  const dispatch = useDispatch();
  const trail = useSelector(getTrail);
  const site = useSelector(getActiveSite);
  const layout = useSelector((state) => getLayout(state, siteId));
  const [headerHeight, setHeaderHeight] = useState(0);
  const systemMessages = useSelector(getSystemMessages);
  const siteSetAt = useSelector(getSiteSetAt);

  const [awaitingSiteSet, setAwaitingSiteSet] = useState(true);

  const getSystemMessagesHeight = () =>
    document.querySelector('.page-header-component')?.offsetHeight || 0;

  const updateHeaderHeight = () => {
    setHeaderHeight(getSystemMessagesHeight());
  };

  useEffect(() => {
    setHeaderHeight(getSystemMessagesHeight());
  }, [systemMessages, headerHeight]);

  useEffect(() => {
    window.addEventListener('resize', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  useEffect(() => {
    setAwaitingSiteSet(false);
  }, [siteSetAt]);

  useEffect(() => {
    if (siteId) {
      setAwaitingSiteSet(true);
      dispatch(setActiveSite(siteId));
    }
  }, [siteId]);

  const { pages } = layout.pages ? layout : { pages: [] };

  const pageConfig = pages?.find(({ path }) => path.toLowerCase() === page);
  const showSidebar = (withSidebar && pages.length > 1) || isTenantAdminView;
  if (pageConfig)
    pageConfig.pageIndex = pages.findIndex(({ path }) => page.toLowerCase() === path.toLowerCase());
  const { title: pageTitle, pageIndex: index } = pageConfig || {};

  const onResetComponent = () => dispatch(setActiveComponentId(null));

  const resolvedPageTitle = pageTitle ? { type: 'string', value: pageTitle, index } : undefined;

  const resolvedTitle = title ||
    resolvedPageTitle || { type: 'breadcrumb', value: staticTrail || trail };

  const validSite = site?.org && site.id;

  const hasPermissions = useSelector((state) =>
    getHasPermission(state, requiredPermission, { org: site.org, site: site.id })
  );
  const shouldRedirect = requiredPermission ? !hasPermissions : false;

  if (!awaitingSiteSet && !isTenantAdminView && (!validSite || shouldRedirect)) {
    return (
      <Redirect
        exact
        to="/sites/"
      />
    );
  }

  return (
    <>
      {showSidebar && (
        <Sidebar
          pages={pages}
          site={site}
          headerHeight={headerHeight}
        />
      )}

      <section className={`base-view ${className} ${showSidebar ? '' : 'no-side-margin'}`}>
        <PageHeader
          site={!isTenantAdminView && site}
          title={{
            type: 'string',
            value: resolvedTitle,
          }}
          singlePage={pages.length === 1}
          isTenantAdminView={isTenantAdminView}
          dispatchResetComponent={onResetComponent}
        >
          {headerChildren}
          {withConnectivity && !noSiteView && !siteAdminView && (
            <ConnectivityStatus siteId={site.id} />
          )}
          {withRefresh && <PollingRefresh siteId={site.id} />}
          <Notifications />
          <UserInfo />
          <HeaderSettings
            site={site}
            noSiteView={noSiteView}
          />
        </PageHeader>
        <div
          className="panel"
          style={{
            paddingTop: headerHeight,
            paddingLeft: withPadding ? '1rem' : 0,
            paddingRight: withPadding ? '1rem' : 0,
          }}
        >
          {/* {children} */}
        </div>
      </section>
    </>
  );
};

export default BaseView;
