import React from 'react'; // eslint-disable-line no-unused-vars
import { Icon, Button } from '@iq/react-components';
import classnames from 'classnames';

import { TYPES } from '../../../bundles/notifications';

const Notification = ({
  notification,
  onClear = () => ({}),
  onExpand = () => ({}),
  depth = 0,
  stacked = false,
  colorPanel = false,
}) => {
  const { type, title, message, extra, status, read, visible, id, items } = notification;

  const actionable = (!visible || type === TYPES.actionable) && typeof onClear === 'function';
  const expandable = items?.length > 1;
  const expanded = !items;

  const icon =
    {
      info: 'abb-information-circle-1',
      success: 'check-circle',
      warning: 'error',
      error: 'cancel',
    }[status] || 'abb-information-circle-1';

  const className = classnames('notification', {
    read,
    filled: colorPanel,
    removable: actionable,
    expanded,
    stacked,
    actionable,
  });

  let messageGroup = message ? <div className="message">{message}</div> : null;
  if (Array.isArray(message)) {
    messageGroup = message.map((msg, i) => {
      const key = `message-${i}`;
      return (
        <div
          key={key}
          className="message"
        >
          {msg}
        </div>
      );
    });
  }

  return (
    <div
      className={className}
      style={{
        top: `${4 * depth}px`,
        left: `-${4 * depth}px`,
      }}
    >
      <div className={`notification-wrapper ${status || 'info'}`}>
        <div className={`notification--icon ${status || 'info'}`}>
          <Icon icon={icon} />
        </div>
        <div className="notification--body">
          <div className="title">{title}</div>
          {messageGroup && <div className="message">{messageGroup}</div>}
          {!!extra && <div className="muted">{extra}</div>}
        </div>
        {expandable && (
          <Button
            className="expand"
            activity="secondary"
            design="outlined"
            slim
            onClick={(e) => onExpand(e, id)}
          >
            Expand
          </Button>
        )}
        {actionable && !expandable && (
          <Button
            className="clear"
            activity="secondary"
            design="outlined"
            slim
            onClick={(e) => onClear(e, id)}
          >
            Dismiss
          </Button>
        )}
      </div>
    </div>
  );
};

export default Notification;
