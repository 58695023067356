import React from 'react';
import { Button, Icon } from '@iq/react-components';

const ThemeSwitch = ({ nightmode, onChange, className }) => (
  <div className={`theme-swtich-component ${className || ''}`}>
    <div className="theme-switch">
      <Button
        className="toggle-nightmode"
        design="text"
        tabIndex="-1"
        tooltip={nightmode ? 'Day mode' : 'Night mode'}
      >
        <Icon
          icon={!nightmode ? 'toggle-off' : 'toggle-on'}
          onClick={onChange}
        />
        <Icon icon="brightness-2" />
      </Button>
    </div>
  </div>
);

export default ThemeSwitch;
