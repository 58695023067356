import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, Button } from '@iq/react-components';
import { NotificationsSharp as NotificationsIcon } from '@mui/icons-material';

import {
  getNotifications,
  removeNotification,
  removeNotificationsByType,
  markNotificationsRead,
  cancelNotificationTimer,
  startNotificationTimer,
  TYPES,
} from '../../bundles/notifications';
import { getActiveUiMode } from '../../bundles/sites';
import NotificationsPanel from './NotificationsPanel';
import GlobalMessagePortal from './GlobalMessagePortal';
import Notification from './Notification';
import Pill from '../Pill';

const Notifications = ({ portalOnly = false }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);
  const uiMode = useSelector(getActiveUiMode);
  const [showPanel, setShowPanel] = useState(false);
  const [expandedIds, setExpandedIds] = useState([]);

  const toggleNotificationsPanel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (showPanel) {
      dispatch(markNotificationsRead());
    }
    setShowPanel(!showPanel);
  };

  const groupedNotifications = useMemo(() => {
    const grouped = Object.values(
      notifications.reduce((acc, notification) => {
        const { groupId, id } = notification;
        if (groupId) {
          if (!acc[groupId]) {
            acc[groupId] = { ...notification, items: [notification] };
          } else {
            acc[groupId].items = [...acc[groupId].items, notification];
          }
        } else {
          acc[id] = { ...notification, items: [] };
        }
        return acc;
      }, {})
    );
    return grouped.map((group) => {
      if (group.items.length > 1) {
        return {
          ...group,
          visible: group.items.some((n) => n.visible),
          read: group.items.every((n) => n.read),
        };
      }
      return group;
    });
  }, [notifications]);

  const visible = useMemo(
    () => groupedNotifications.filter((n) => n.visible),
    [groupedNotifications]
  );
  const displayed = visible[0];
  const expanded = expandedIds.includes(displayed?.id);
  const panelsToRender = displayed && displayed.items.length > 1 ? 3 : 1;

  useEffect(() => {
    dispatch(cancelNotificationTimer());
    if (displayed) {
      if (displayed.items.length) {
        displayed.items.forEach((item) => dispatch(startNotificationTimer(item.id)));
      } else {
        dispatch(startNotificationTimer(displayed.id));
      }
    }
  }, [JSON.stringify(displayed)]);

  const readable = useMemo(
    () => groupedNotifications.filter((n) => n.type !== TYPES.transient),
    [groupedNotifications]
  );

  const unread = useMemo(() => readable.filter((n) => !n.read), [groupedNotifications]);

  const handleRemoveNotificationById = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(removeNotification(id));
  };

  const handleRemoveAllActionableNotifications = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(removeNotificationsByType(TYPES.actionable));
    dispatch(removeNotificationsByType(TYPES.persistent));
  };

  const handleClosePanel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowPanel(false);
    dispatch(markNotificationsRead());
  };

  const handleCancelTimer = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(cancelNotificationTimer());
  };

  const handleRestartTimer = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (displayed) {
      if (displayed.items.length) {
        displayed.items.forEach((item) => dispatch(startNotificationTimer(item.id)));
      } else {
        dispatch(startNotificationTimer(displayed.id));
      }
    }
  };

  const handleExpandNotificationGroup = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    if (groupedNotifications.find((n) => n.id === id && n.items.length > 1)) {
      if (expandedIds.includes(id)) {
        setExpandedIds(() => expandedIds.filter((i) => i !== id));
      } else {
        setExpandedIds(() => [...expandedIds, id]);
      }
    }
  };

  return (
    <>
      {!portalOnly && (
        <div
          className={`notifications--panel-toggler ${unread.length === 0 ? 'muted' : ''}`}
          onClick={toggleNotificationsPanel}
        >
          <Button
            design="text"
            tooltip="Notifications"
            className={uiMode === 'Lumada' && 'lumadabutton'}
          >
            {uiMode === 'Lumada' ? (
              <NotificationsIcon sx={{ fontSize: '1.6rem' }} />
            ) : (
              <Icon
                icon="notifications"
                fill="gray"
              />
            )}
          </Button>
          {!!unread.length && <Pill text={unread.length} />}
        </div>
      )}
      {showPanel && (
        <NotificationsPanel
          notifications={readable}
          onClear={handleRemoveNotificationById}
          onClearAll={handleRemoveAllActionableNotifications}
          onClose={handleClosePanel}
        />
      )}
      <GlobalMessagePortal>
        <div
          className={`notifications--overlay ${displayed ? 'visible' : 'hidden'}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onMouseOver={handleCancelTimer}
          onMouseOut={handleRestartTimer}
        >
          {displayed &&
            !expanded &&
            Array(panelsToRender)
              .fill(0)
              .map((_, i) => (
                <Notification
                  key={`notification-${i}`}
                  notification={displayed}
                  depth={panelsToRender - 1 - i}
                  colorPanel={panelsToRender === 1 || i === 2}
                  onClear={handleRemoveNotificationById}
                  onExpand={handleExpandNotificationGroup}
                  onClick={(e) => handleExpandNotificationGroup(e, displayed.id)}
                  stacked
                />
              ))}
          {displayed && expanded && (
            <>
              <div className="collapser">
                <Button
                  className="clear"
                  activity="secondary"
                  slim
                  onClick={(e) => handleExpandNotificationGroup(e, displayed.id)}
                >
                  Collapse
                </Button>
              </div>
              {displayed.items.map((item, i) => (
                <Notification
                  key={`${displayed.id}-${i}`}
                  notification={item}
                  onClear={handleRemoveNotificationById}
                  colorPanel
                />
              ))}
            </>
          )}
        </div>
      </GlobalMessagePortal>
    </>
  );
};

export default Notifications;
