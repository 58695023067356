.loading-main-content {
  opacity: 0.3;
}

.main-content {
  opacity: 1;
  height: 100%;
}

.no-access,
.page-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvUm91dGVyIiwic291cmNlcyI6WyJSb3V0ZXIuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFOzs7QUFHRjtFQUNFO0VBQ0E7OztBQUdGO0FBQUE7RUFFRTtFQUNBO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5sb2FkaW5nLW1haW4tY29udGVudCB7XG4gIG9wYWNpdHk6IDAuMztcbn1cblxuLm1haW4tY29udGVudCB7XG4gIG9wYWNpdHk6IDE7XG4gIGhlaWdodDogMTAwJTtcbn1cblxuLm5vLWFjY2Vzcyxcbi5wYWdlLW5vdC1mb3VuZCB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBoZWlnaHQ6IDEwMHZoO1xufVxuIl19 */