import React, { useMemo, useCallback } from 'react';
import { Creatable } from '@iq/react-components';
import '../widgets/SelectWidget.scss';

const CreatableField = (props) => {
  const {
    disabled,
    readonly,
    required,
    formData: value,
    schema: {
      handleCreatable,
      validateCreatable,
      default: defaultValue,
      isMulti,
      isClearable,
      noOptionsMessage,
      placeholder: schemaPlaceholder,
      items: { enum: enums, enumNames } = {},
      label,
    },
    onChange,
    ...otherProps
  } = props;

  const getLabel = (val) => (enumNames.find((o) => o.value === val) || {}).label;

  const opts = useMemo(
    () =>
      enums.map((val, i) => ({
        value: val,
        label: enumNames[i],
      })),
    [enumNames, enums]
  );

  const theValue = useMemo(() => {
    if (isMulti) {
      return (value || []).map((v) => ({
        label: enumNames[enums.findIndex((e) => e === v)],
        value: v,
      }));
    }
    if (value || value === 0) {
      return { label: getLabel(value), value };
    }
    if (defaultValue) {
      return { label: getLabel(defaultValue), defaultValue };
    }
    return undefined;
  }, [value, defaultValue, enums]);

  const setValues = useCallback(
    (selected) => {
      if (isMulti) {
        onChange((selected || []).map((s) => s.value));
      } else {
        onChange(selected && (selected.value || selected.value === 0) ? selected.value : null);
      }
    },
    [onChange, isMulti]
  );

  const getEmptyMsg = (text) => <div>{text}</div>;

  const overrideStyles = {
    container: (base) => ({ ...base, lineHeight: 'normal' }),
    singleValue: (base) => ({ ...base, lineHeight: 'normal' }),
    control: (base) => ({ ...base, lineHeight: 'normal' }),
  };

  return (
    <>
      <label className="control-label">{label}</label>
      <Creatable
        {...otherProps}
        className="schema-select-widget"
        isMulti={isMulti}
        isClearable={isClearable}
        closeMenuOnSelect={!isMulti}
        disabled={disabled}
        readOnly={readonly}
        required={required}
        placeholder={schemaPlaceholder}
        menuPlacement="auto"
        value={theValue}
        onChange={setValues}
        isValidNewOption={validateCreatable}
        onCreateOption={handleCreatable}
        options={opts}
        noOptionsMessage={() => getEmptyMsg(noOptionsMessage || 'No options')}
        styles={overrideStyles}
      />
    </>
  );
};

export default CreatableField;
