import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { setActiveComponentId } from '../../bundles/application';
import { fetchProjectAction, getProject, updateProject } from '../../bundles/projects';
import { getActiveSite } from '../../bundles/sites';

import Sidebar from '../Sidebar';
import PageHeader from '../PageHeader';

import HeaderSettings from '../PageHeader/HeaderSettings';
import ConnectivityStatus from '../PageHeader/ConnectivityStatus';
import Notifications from '../Notifications';
import ProjectCreateUpdateView from '../ProjectCreateUpdateView';

const BaseViewMach = ({
  withConnectivity = true,
  isTenantAdminView = false,
  noSiteView = false,
  siteAdminView = false,
  headerChildren,
  className = '',
}) => {
  const dispatch = useDispatch();
  const site = useSelector(getActiveSite);
  const params = useParams();
  const { projectId } = params;
  const project = useSelector((state) => getProject(state, projectId));

  useEffect(() => {
    if (project) return;

    dispatch(fetchProjectAction(projectId));
  }, [dispatch, projectId]);

  const onResetComponent = () => dispatch(setActiveComponentId(null));

  if (project) {
    return (
      <>
        <Sidebar
          projectId={projectId}
          ecProject={project?.ecModel}
        />
        <section className={`base-view ${className}`}>
          <PageHeader
            site={!isTenantAdminView && site}
            title={{
              type: 'string',
              value: project.name,
            }}
            singlePage={true}
            isTenantAdminView={isTenantAdminView}
            dispatchResetComponent={onResetComponent}
          >
            {headerChildren}
            {withConnectivity && !noSiteView && !siteAdminView && (
              <ConnectivityStatus siteId={site.id} />
            )}
            <Notifications />

            <HeaderSettings
              site={site}
              noSiteView={noSiteView}
            />
          </PageHeader>

          <div className="panel">
            <div className="panel-header">
              <span>MIMS Configuration</span>
              <div className="panel-header-button">
                <ProjectCreateUpdateView
                  title={'Update Project'}
                  onSave={updateProject}
                  showButtonDownload={false}
                  createUpdateButtonText="Upload new config"
                />
              </div>
            </div>

            <div className="mims-config">
              <div className="mims-config-type">
                <p>Id</p>
                <p>Name</p>
                <p>MIMS IP</p>
                <p>Subscription Type</p>
                <p>Sample Rate</p>
                <p>MIMS Module Id</p>
              </div>

              <div className="mims-config-value">
                <p>{project.id}</p>
                <p>{project.name}</p>
                <p>{project.ip}</p>
                <p>{project.subscriptionType}</p>
                <p>{project.sampleRate}</p>
                <p>{project.moduleId}</p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  return null;
};

export default BaseViewMach;
