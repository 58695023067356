import React, { useMemo, useCallback, useState } from 'react';
import { Select } from '@iq/react-components';
import './SelectWidget.scss';
import { NEW_PANEL } from '../../../constants';

const SelectWidget = (props) => {
  const {
    disabled,
    readonly,
    required,
    formContext,
    value,
    schema: {
      default: defaultValue,
      isMulti,
      isClearable,
      noOptionsMessage,
      placeholder: schemaPlaceholder,
    },
    options: { enumOptions = [] },
    onChange,
    ...otherProps
  } = props;

  const getLabel = (val) => (enumOptions.find((o) => o.value === val) || {}).label;
  const [isChanged, setIsChanged] = useState(false);
  if (formContext.type === NEW_PANEL && !isChanged && defaultValue && value.length === 0) {
    onChange(defaultValue);
  }
  const theValue = useMemo(() => {
    if (isMulti) {
      return (value || []).map((v) => ({
        label: (enumOptions.find((o) => o.value === v) || {}).label,
        value: v,
      }));
    }
    if (value || value === 0) {
      return { label: getLabel(value), value };
    }
    if (defaultValue) {
      return { label: getLabel(defaultValue), defaultValue };
    }
    return undefined;
  }, [value, defaultValue, enumOptions]);

  const setValues = useCallback(
    (selected) => {
      if (isMulti) {
        setIsChanged(true);
        onChange((selected || []).map((s) => s.value));
      } else {
        onChange(selected && (selected.value || selected.value === 0) ? selected.value : null);
      }
    },
    [onChange, isMulti]
  );

  const getEmptyMsg = (text) => <div>{text}</div>;

  const overrideStyles = {
    container: (base) => ({ ...base, lineHeight: 'normal' }),
    singleValue: (base) => ({ ...base, lineHeight: 'normal' }),
    control: (base) => ({ ...base, lineHeight: 'normal' }),
  };

  return (
    <Select
      {...otherProps}
      className="schema-select-widget"
      isMulti={isMulti}
      isClearable={isClearable}
      closeMenuOnSelect={!isMulti}
      disabled={disabled}
      readOnly={readonly}
      required={required}
      placeholder={schemaPlaceholder}
      menuPlacement="auto"
      value={theValue}
      onChange={setValues}
      options={enumOptions}
      noOptionsMessage={() => getEmptyMsg(noOptionsMessage || 'No options')}
      styles={overrideStyles}
    />
  );
};

export default SelectWidget;
