import React from 'react';
import { Textarea } from '@iq/react-components';

const TextareaWidget = ({
  value,
  schema: { default: defaultValue, disabled, readonly, required, placeholder, rows = 5 },
  onChange,
}) => {
  const defaultVal = defaultValue || '';
  const useDefault = !value;
  const val = useDefault ? defaultVal : value;

  return (
    <Textarea
      required={required}
      disabled={disabled}
      readOnly={readonly}
      placeholder={placeholder}
      rows={rows}
      defaultValue={val}
      onChange={(event) => {
        let v = event.target.value;
        if (typeof v === 'string') {
          v = v.trim();
        }
        onChange(v);
      }}
    />
  );
};

export default TextareaWidget;
