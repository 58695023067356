import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DropdownMenu } from '../DropdownMenu';
import { getProjects, deleteProject, createProject } from '../../bundles/projects';

import AppContext from '../../AppContext';
import ConfirmationDialog from '../ConfirmationDialog';
import Notifications from '../Notifications';
import PageHeader from '../PageHeader';
import SiteThumbnail from '../SiteThumbnail';
import identiqBlack from '../../../images/identiq-black.svg';
import identiqWhite from '../../../images/identiq-white.svg';

import ProjectCreateUpdateView from '../ProjectCreateUpdateView';

const ProjectListView = ({ realm }) => {
  const { theme } = useContext(AppContext);

  const projects = useSelector(getProjects);

  const [projectToDelete, setProjectToDelete] = useState(false);

  const nightmode = theme === 'dark';

  const dispatch = useDispatch();
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const loader = document.getElementById('first-loader');
    if (loader) loader.remove();
  }, [projects]);

  const onDeleteProject = (name, id) => {
    setProjectToDelete({ name, id });
  };

  const itemMenuOptions = (projectName, projectId) => [
    {
      component: <>Delete project</>,
      onSelect: () => onDeleteProject(projectName, projectId),
    },
  ];

  const projectsWithThumbs = useMemo(() => {
    if (projects && projects.length > 0) {
      return projects.map((project) => {
        return project;
      });
    }
    return [];
  }, [projects]);

  const filteredProjects = useMemo(() => {
    if (projectsWithThumbs && projectsWithThumbs.length > 0) {
      return projectsWithThumbs.filter(({ name = {} }) => {
        if (name) {
          const needles = [name].map((s) => s.toLowerCase());
          return needles.some((needle) => needle.includes(filter.toLowerCase()));
        }
        return false;
      });
    }
    return [];
  }, [projectsWithThumbs, filter]);

  return (
    <>
      <Notifications portalOnly />
      <PageHeader
        title={{
          type: 'image',
          value: nightmode ? identiqWhite : identiqBlack,
        }}
        realm={realm}
        sitesList
        setFilter={setFilter}
      >
        <Notifications />
      </PageHeader>
      <div className="site-list-view-component">
        <div className={`sidebar ${nightmode ? 'dark-theme' : 'light-theme'}`}>
          <div className="panel"></div>
          <div className="site-list custom-scrollbar">
            <div className="projects-projects">
              Projects
              <div className="project-btn-component">
                <ProjectCreateUpdateView
                  title="Create Project"
                  onSave={createProject}
                />
              </div>
            </div>
            {filteredProjects.map((project) => (
              <Link
                className="site site-list-link"
                key={project.name}
                to={`/mach/${project.id}/`}
              >
                <div className="thumbnail">
                  <SiteThumbnail name={project.name} />
                </div>
                <div className="info">
                  <div className="name">{project.name}</div>
                  <div className="location">&nbsp;</div>
                </div>
                <div className="drop-down-menu">
                  <DropdownMenu
                    trigger="avt-menu-dots"
                    menuOptions={itemMenuOptions(project.name, project.id)}
                    className="site-item-menu"
                    menuXPlacement="left"
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
        {projectToDelete && (
          <ConfirmationDialog
            onCancel={() => setProjectToDelete(null)}
            onConfirm={() => {
              setProjectToDelete(null);
              dispatch(deleteProject(projectToDelete.id, projectToDelete.name));
            }}
            confirmType="danger"
            title="Delete project"
            body={
              <>
                Are you sure you want to delete project <b>{projectToDelete.name}</b>?
              </>
            }
            confirmText="Yes, delete"
            showCancel={true}
            cancelText="No, cancel"
          />
        )}
      </div>
    </>
  );
};

export default ProjectListView;
