/* eslint-disable max-len */
import React, { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HvProvider } from '@hitachivantara/uikit-react-core';
import { Router as ReactRouter, Route, Switch, Redirect } from 'react-router-dom';

import { getActiveSite } from '../../bundles/sites';
import { requestProjects } from '../../bundles/projects';
import { requestInstructionals } from '../../bundles/files';
import { getOnline } from '../../bundles/notifications';

import BaseView from '../BaseView';
import BaseViewMach from '../BaseViewMach';
import BaseViewOverview from '../BaseViewOverview';
import BaseViewIoTCloud from '../BaseViewIoTCloud';

import SiteType from '../SiteType';

import SourceConnectivityNotification from '../Notifications/SourceConnectivityNotification';

import GlobalMessagePortal from '../Notifications/GlobalMessagePortal';
import TopNotification from '../Notifications/TopNotification';
import AppContext from '../../AppContext';
import ProjectListView from '../ProjectListView';

const NotFoundPage = () => (
  <div className="page-not-found">
    <h2>Sorry, page not found...</h2>
  </div>
);

// Page when user is not "admin":
const NoAccessPage = () => (
  <div className="no-access">
    <h2>Sorry, you don't have access to view this page. Contact the local Admin for access.</h2>
  </div>
);

const Router = ({ history }) => {
  const dispatch = useDispatch();

  /* eslint-disable no-unused-vars */
  const [isLoadingSites] = useState(false);

  const site = useSelector(getActiveSite);

  const online = useSelector(getOnline);

  const validSite = site?.org && site.id;
  const isLumadaSite = validSite && site.uiMode === 'Lumada';

  const { setTheme, theme } = useContext(AppContext);

  const setAvtTheme = useCallback(() => {
    setTheme(theme);
  }, [theme]);

  const hvTheme = useMemo(() => (theme === 'dark' ? 'wicked' : null), [theme]);

  const removeFirstLoader = () => {
    const loader = document.getElementById('first-loader');
    if (loader) loader.remove();
  };

  useEffect(() => {
    if (online) {
      dispatch(requestProjects());
      dispatch(requestInstructionals());
    }
  }, [online]);

  if (isLoadingSites) {
    return (
      <div>
        {!online && (
          <GlobalMessagePortal>
            <TopNotification
              icon="abb-wifi-network-disconnected"
              message="No internet connection"
            />
          </GlobalMessagePortal>
        )}
      </div>
    );
  }

  if (history !== '/test/') {
    removeFirstLoader();
  } else if (history !== '/projects/') {
    removeFirstLoader();
    return <ProjectListView />;
  }

  return (
    <HvProvider
      uiKitTheme={hvTheme}
      changeTheme={setAvtTheme}
    >
      <div className={'main-content'}>
        <ReactRouter history={history}>
          <Switch>
            {/* Pre-site routes */}
            <Route
              exact
              path="/"
            >
              <Redirect to="/projects/" />
            </Route>
            <Route
              exact
              path="/projects/"
              render={(props) => (
                <SiteType
                  {...props}
                  sitesReady={true}
                  noSiteView
                />
              )}
            />
            <Route
              path="/tenant-administration/users"
              render={(props) =>
                (<BaseView
                  title={{
                    type: 'string',
                    value: 'Tenant Administration - Users',
                  }}
                  noSitesView
                  className="manage-view"
                  isTenantAdminView
                  {...props}
                ></BaseView>)(<Redirect to="/projects/" />)
              }
            />

            {/* NEW TEST SITE AFTER CREATING NEW PROJECT */}

            <Route
              path="/mach/:projectId"
              render={() => (
                <BaseViewMach
                  site={site}
                  withSidebar={true}
                  className="site-detail-view-component"
                  withPadding={false}
                />
              )}
            />

            <Route
              path="/overview/:projectId"
              render={() => (
                <BaseViewOverview
                  site={site}
                  withSidebar={true}
                  withRefresh
                  title={
                    isLumadaSite && {
                      type: 'image',
                    }
                  }
                  className="site-detail-view-component"
                  withPadding={false}
                />
              )}
            />

            <Route
              path="/iot-cloud/:projectId"
              render={() => (
                <BaseViewIoTCloud
                  site={site}
                  withSidebar={true}
                  className="site-detail-view-component"
                  withPadding={false}
                ></BaseViewIoTCloud>
              )}
            />

            <Route
              component={NotFoundPage}
              path="/projects/notfound"
            />
            <Route
              component={NoAccessPage}
              path="/projects/noaccess"
            />
          </Switch>
        </ReactRouter>
        {!online && (
          <GlobalMessagePortal site={site}>
            <TopNotification
              icon="abb-wifi-network-disconnected"
              message="No internet connection"
            />
          </GlobalMessagePortal>
        )}
        <GlobalMessagePortal>{site.id && <SourceConnectivityNotification />}</GlobalMessagePortal>
      </div>
    </HvProvider>
  );
};

export default Router;
