import React, { useRef } from 'react';
import { components } from 'react-select';
import { Icon, Select, useTheme, colors, Button } from '@iq/react-components';

const DropdownMenu = ({
  trigger,
  triggerSize = 's',
  triggerIconColor,
  triggerThemeDarkColor,
  triggerThemeLightColor,
  triggerUnderlay,
  menuOptions,
  value,
  className = '',
  tooltip = 'More...',
  isMulti = false,
  menuXPlacement = 'right',
  menuYPlacement = 'auto',
  onChange = (selection) => selection,
  onMenuOpen,
  onMenuClose,
  styles = {},
  fullHeight = false,
  ...extraProps
}) => {
  const theme = useTheme();
  const dropdownRef = useRef();

  const handleChange = (selection) => {
    if (!isMulti && selection.onSelect && typeof selection.onSelect === 'function') {
      selection.onSelect(selection);
    }
    onChange(selection);
  };

  const handleMenuClose = () => {
    if (dropdownRef.current) {
      dropdownRef.current.blur();
    }
    onMenuClose?.();
  };

  const DropdownIndicator = (props) => {
    let customIndicator = components.DownChevron;

    const indicatorColor =
      triggerIconColor ||
      (theme === 'dark'
        ? triggerThemeDarkColor || colors.DarkApplicationPrimaryText
        : triggerThemeLightColor || colors.LightApplicationSecondaryText);

    if (typeof props.selectProps.trigger === 'string') {
      customIndicator = (
        <Button
          design="text"
          tooltip={tooltip}
          style={{ height: '1rem' }}
        >
          <Icon
            style={{ color: `${indicatorColor}` }}
            icon={props.selectProps.trigger}
            size={triggerSize}
          />
        </Button>
      );
    }

    if (React.isValidElement(props.selectProps.trigger)) {
      customIndicator = props.selectProps.trigger;
    }
    return (
      <components.DropdownIndicator {...props}>
        {React.cloneElement(customIndicator, {
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
        })}
      </components.DropdownIndicator>
    );
  };

  const Option = (props) => (
    <components.Option
      {...{
        ...props,
        innerProps: {
          ...props.innerProps,
          onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
            props.innerProps.onClick(e);
          },
        },
      }}
    >
      {props.data.component || props.data.label}
    </components.Option>
  );

  const triggerColor =
    theme === 'dark' ? colors.DarkApplicationSecondaryText : colors.LightApplicationSecondaryText;

  const dropdownStyles = {
    container: (base) => ({
      ...base,
      width: 'fit-content',
      ...(styles.container || {}),
    }),
    input: () => ({ cursor: 'none' }),
    valueContainer: () => ({ height: '0px', width: '0px', overflow: 'hidden' }),
    indicatorsContainer: (base) => ({
      ...base,
      borderRadius: '0.2rem',
      backgroundColor: triggerUnderlay || 'transparent',
      flexGrow: '1',
      ...(styles.indicatorsContainer || {}),
    }),
    dropdownIndicator: (base) => ({
      ...base,
      cursor: 'pointer',
      flexGrow: '1',
      padding: trigger && React.isValidElement(trigger) ? '0px' : base.padding,
      color: triggerColor,
      '&:hover': { color: triggerColor },
      ...(styles.dropdownIndicator || {}),
    }),
    menu: (base) => ({
      ...base,
      width: 'fit-content',
      minWidth: '100%',
      left: menuXPlacement === 'right' ? 0 : 'auto',
      right: menuXPlacement === 'right' ? 'auto' : 0,
      top: 'unset',
      border: `1px solid ${theme === 'dark' ? colors.Grey70 : colors.Grey20}`,
      boxShadow: '0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.15)',
      zIndex: '1000',
      ...(styles.menu || {}),
    }),
    menuList: (base) => ({
      ...base,
      ...(styles.menuList || {}),
      scrollbarColor: `${
        theme === 'dark'
          ? 'rgba(255, 255, 255, .25) rgba(0, 0, 0, 0)'
          : 'rgba(0, 0, 0, .25) rgba(0, 0, 0, 0)'
      }`,
      '::-webkit-scrollbar': {
        width: '7px',
      },
      '::-webkit-scrollbar-thumb': {
        background: `${theme === 'dark' ? 'rgba(255, 255, 255, .25)' : 'rgba(0, 0, 0, .25)'}`,
        borderRadius: '7px',
        boxShadow: `${
          theme === 'dark' ? '0 0 1px rgba(0, 0, 0, .5)' : '"0 0 1px rgba(255, 255, 255, .5)"'
        }`,
      },
    }),
    group: (base) => ({
      ...base,
      paddingTop: '0',
      paddingBottom: '0',
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme === 'dark' ? colors.Grey70 : colors.Grey20}`,
        paddingBottom: '4px',
      },
    }),
    option: (base) => ({
      ...base,
      whiteSpace: 'nowrap',
      borderRadius: '0.2rem',
      ...(styles.option || {}),
    }),
    control: (base) => ({
      ...base,
      ...(fullHeight ? {} : { minHeight: 'unset' }),
      border: 'none',
      '&:hover': { border: 'none', borderColor: 'transparent' },
      '&:active': { border: 'none', borderColor: 'transparent' },
      boxShadow: 'none',
      backgroundColor: 'transparent',
      justifyContent: 'flex-start',
      ...(styles.control || {}),
    }),
  };
  return (
    <Select
      isMulti={isMulti}
      trigger={trigger}
      options={menuOptions}
      value={value}
      className={className}
      isSearchable={false}
      onChange={handleChange}
      onMenuOpen={onMenuOpen}
      onMenuClose={handleMenuClose}
      menuPlacement={menuYPlacement}
      isOptionSelected={() => false}
      hideSelectedOptions={false}
      controlShouldRenderValue={false}
      components={{
        DropdownIndicator,
        Option,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
        Placeholder: () => null,
      }}
      styles={dropdownStyles}
      innerRef={dropdownRef}
      {...extraProps}
    />
  );
};

export default DropdownMenu;
