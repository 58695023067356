import React from 'react';
import TreeView, { flattenTree } from 'react-accessible-treeview';
import './TreeView.scss';

const FoldableTreeView = ({ data }) => {
  const treeData = flattenTree(data);

  return (
    <TreeView
      data={treeData}
      className="foldable-tree-view"
      nodeRenderer={({ element, getNodeProps, level }) => (
        <div
          {...getNodeProps()}
          style={{ paddingLeft: 20 * (level - 1) }}
        >
          {element.name}
        </div>
      )}
    />
  );
};

export default FoldableTreeView;
