import React, { useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, useTheme, colors } from '@iq/react-components';

import {
  getStaticComponents,
  getComponentsLoaded,
  requestComponents,
} from '../../../bundles/components';
import { getActiveSiteId } from '../../../bundles/sites';
import Loader from '../../Loader';

export default function ComponentSelect({
  label,
  icon,
  isMulti = true,
  onChange = () => null,
  value,
  closeMenuOnSelect,
  popout = false,
  placeholder,
  availableComponents,
  menuXPlacement = 'right',
  menuYPlacement = 'auto',
  menuMaxHeight,
  noSiteView = false,
  fullHeight = false,
  customFilter = () => true,
  ...props
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const siteId = useSelector(getActiveSiteId);
  const componentsLoaded = useSelector(getComponentsLoaded);
  const components = useSelector(getStaticComponents);

  const compSort = (items) =>
    items?.sort((a, b) => {
      const { name: nameA, itemDesignation: desA } = a;
      const { name: nameB, itemDesignation: desB } = b;
      return (desA + nameA).localeCompare(desB + nameB);
    });

  useEffect(() => {
    if (siteId && !componentsLoaded) {
      dispatch(requestComponents(siteId));
    }
  }, [siteId, componentsLoaded]);

  const sortedComponents = useMemo(() => compSort(components), [JSON.stringify(components)]);
  const sortedAvailableComponents = useMemo(
    () => compSort(availableComponents),
    [availableComponents]
  );

  const overrideStyles = () => ({
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? colors.Grey10 : base.color,
    }),
    menu: (base) => ({
      ...base,
      ...(fullHeight ? {} : { minHeight: 'unset' }),
      border: `1px solid ${theme === 'dark' ? colors.Grey70 : colors.Grey20}`,
      marginTop: '0.2rem',
      zIndex: '1000',
    }),
    control: (base) => ({
      ...base,
      lineHeight: '1.3rem',
      backgroundColor: `${theme === 'dark' ? colors.Grey90 : colors.Grey0}`,
    }),
    menuList: (base) => ({
      ...base,
      ...(menuMaxHeight ? { maxHeight: menuMaxHeight } : {}),
      scrollbarColor: `${
        theme === 'dark'
          ? 'rgba(255, 255, 255, .25) rgba(0, 0, 0, 0)'
          : 'rgba(0, 0, 0, .25) rgba(0, 0, 0, 0)'
      }`,
      '::-webkit-scrollbar': {
        width: '7px',
      },
      '::-webkit-scrollbar-thumb': {
        background: `${theme === 'dark' ? 'rgba(255, 255, 255, .25)' : 'rgba(0, 0, 0, .25)'}`,
        borderRadius: '7px',
        boxShadow: `${
          theme === 'dark' ? '0 0 1px rgba(0, 0, 0, .5)' : '"0 0 1px rgba(255, 255, 255, .5)"'
        }`,
      },
    }),
  });

  const componentOptions = useMemo(
    () =>
      (sortedAvailableComponents || sortedComponents).filter(customFilter).map((c) => ({
        value: c.id,
        label: `[ ${c.itemDesignation} ] ${c.name}`,
      })),
    [sortedComponents, sortedAvailableComponents, customFilter]
  );

  const handleOnChange = useCallback(
    (selection) => {
      if (isMulti) {
        onChange(selection ? selection.map((option) => option.value) : []);
      } else {
        onChange(selection ? selection.value : undefined);
      }
    },
    [onChange, isMulti]
  );

  if (!componentOptions) {
    return <Loader />;
  }

  const localValue = useMemo(() => {
    if (isMulti) {
      return componentOptions.filter((option) => (value || []).find((id) => option.value === id));
    }
    return componentOptions.find((option) => option.value === value);
  }, [value, componentOptions]);

  if (!noSiteView) return null;

  return (
    <Select
      label={popout && label}
      icon={popout && icon}
      isMulti={isMulti}
      onChange={handleOnChange}
      value={localValue}
      options={componentOptions}
      closeMenuOnSelect={!isMulti}
      placeholder={placeholder || 'Search...'}
      menuXPlacement={menuXPlacement}
      menuYPlacement={popout ? 'bottom' : menuYPlacement}
      styles={(menuMaxHeight || !fullHeight) && overrideStyles()}
      {...props}
    />
  );
}
