import React, { useState, useEffect } from 'react';
import { Input, Icon } from '@iq/react-components';
import {
  useFloating,
  useHover,
  useInteractions,
  offset,
  autoUpdate,
  flip,
  shift,
} from '@floating-ui/react';

import Select from '../../CustomSelect';
import './InputWithTooltipField.scss';

/*
 * This component handles a string field with a description and optionally,
 * a boolean toggle that should relate to the string field.
 * The string field can either be a simple input, or if selectOptions
 * are passed in with the schema, a CustomSelect.
 */
const InputWithTooltip = (props) => {
  const {
    schema: { description, title, selectOptions, properties, type, action },
    onChange,
    formData,
  } = props;

  const [inputToggleKey] =
    Object.entries(properties || {}).find(([, value]) => value.type === 'boolean') || [];
  const [inputKey] =
    Object.entries(properties || {}).find(([, value]) => value.type === 'string') || [];

  const [isTooltipVisisble, setIsTooltipVisible] = useState(false);
  const [data, setData] = useState(formData);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const { x, y, strategy, refs, context } = useFloating({
    open: isTooltipVisisble,
    placement: 'right',
    middleware: [offset(15), flip(), shift()],
    onOpenChange: setIsTooltipVisible,
    whileElementsMounted: autoUpdate,
  });

  const handleToggle = () => {
    setData({ ...data, [inputToggleKey]: !data[inputToggleKey] });
  };

  const handleInputChange = (e) => {
    if (type === 'object') {
      setData({ ...data, [inputKey]: e.target.value });
    } else {
      setData(e.target.value);
    }
  };

  const handleSelectChange = (selectedValue) => {
    if (type === 'object') {
      setData({ ...data, [inputKey]: selectedValue });
    } else {
      setData(selectedValue);
    }
  };

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const theValue = type === 'object' ? (data || {})[inputKey] : data;

  return (
    <div className="input-tooltip-wrapper">
      <div className="input-tooltip-top-container">
        <div
          className={`tooltip-container`}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          <p className="input-title">{title}</p>
          <Icon
            size="s"
            icon={action === 'info' ? 'abb-information-circle-1' : 'abb-help-circle-1'}
            className="input-tooltip-description"
          ></Icon>
          {isTooltipVisisble && (
            <div
              className={`tooltip`}
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                background: 'white',
                border: '1px solid black',
                padding: '1rem',
                display: 'flex',
                width: '40rem',
              }}
              {...getFloatingProps()}
            >
              {description}
            </div>
          )}
        </div>
        {inputToggleKey && (
          <div
            className="input-tooltip-toggle"
            onClick={handleToggle}
          >
            <Icon
              icon={`${data[inputToggleKey] ? 'toggle-on' : 'toggle-off'}`}
              className={`${data[inputToggleKey] ? 'toggle-active' : 'toggle-inactive'}`}
              size="l"
            />
            <p className="toggle-text">{data[inputToggleKey] ? 'Disable' : 'Enable'}</p>
          </div>
        )}
      </div>
      {selectOptions ? (
        <Select
          isMulti={false}
          rawOptions={selectOptions}
          value={theValue}
          onChange={handleSelectChange}
        />
      ) : (
        <Input
          formRef
          value={data[inputKey]}
          onChange={handleInputChange}
        />
      )}
    </div>
  );
};

export default InputWithTooltip;
