import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spinner, Icon, colors } from '@iq/react-components';

import FiletypeIcon from '../FiletypeIcon';
import { getUploading, getUploaded } from '../../bundles/files';

const FileUploadStatus = ({ theme }) => {
  const [modalState, setModalState] = useState('maximized');
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);
  const [hideFor, setHideFor] = useState(0);
  const uploading = useSelector(getUploading);
  const uploaded = useSelector(getUploaded);

  const files = [...uploading, ...uploaded];
  useEffect(() => {
    if (!show && uploading.length > hideFor) {
      setShow(true);
    } else if (show && !uploading.length > hideFor) {
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }
    if (uploaded.length === 0) setCount(0);
  }, [uploading, uploaded]);
  useEffect(() => {
    if (uploaded.length > 0) {
      if (uploaded.slice(-1)[0]?.status === 'SUCCESS') {
        setCount(count + 1);
      } else {
        setCount(count);
      }
    }
  }, [uploaded.length]);
  const handleClose = () => {
    setHideFor(uploading.length);
    setShow(false);
  };

  const fileElements = files.map((file, i) => {
    let progressIcon;
    if (file.status === 'SUCCESS') {
      progressIcon = (
        <Icon
          icon="check-circle"
          size="s"
          style={{ color: colors.StatusGreen }}
        />
      );
    } else if (file.status === 'ERROR') {
      progressIcon = (
        <Icon
          icon="abb-minus"
          size="s"
          style={{ color: colors.StatusRed }}
        />
      );
    } else {
      progressIcon = (
        <Spinner
          size="s"
          theme={theme}
        />
      );
    }

    return (
      <div
        className="file"
        key={i}
      >
        <div className="file-icon">
          <FiletypeIcon
            type={file.file.type}
            size="s"
          />
        </div>
        <div className="filename">{file.file.name}</div>
        <div className="file-status">{progressIcon}</div>
      </div>
    );
  });

  return show ? (
    <div className="file-upload-status">
      <div className="header">
        <div className="header-left">
          <div className="label">Upload Status</div>
          <div>
            {count}/{files.length}
          </div>
          {modalState === 'minimized' ? (
            <Icon
              icon="keyboard-arrow-up"
              onClick={() => setModalState('maximized')}
              size="s"
            />
          ) : (
            <Icon
              icon="keyboard-arrow-down"
              size="s"
              onClick={() => setModalState('minimized')}
            />
          )}
        </div>
        <Icon
          icon="abb-close"
          size="s"
          onClick={handleClose}
        />
      </div>

      {modalState === 'maximized' ? (
        <div className="files custom-thin-scrollbar">{fileElements}</div>
      ) : null}
    </div>
  ) : null;
};

export default FileUploadStatus;
