/* eslint-disable default-param-last */
import { call, takeLatest, takeEvery, delay, select, put } from 'redux-saga/effects';

import { refreshSessions, createBookmarkSuccess, updateBookmarkSuccess } from './files';
import { bookmarkCreated, bookmarkDeleted } from './user-settings';

import services from '../services';
import { displayNotification, checkOnline } from './notifications';
import getNotification from './notification-defaults';
import { CLEAR_SITE_DATA } from './application';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const CREATE_BOOKMARK = 'dt/bookmarks/CREATE_BOOKMARK';
const UPDATE_BOOKMARK = 'dt/bookmarks/UPDATE_BOOKMARK';

const DELETE_BOOKMARK_SUCCESS = 'dt/bookmarks/DELETE_BOOKMARK_SUCCESS';
const DELETE_BOOKMARK = 'dt/bookmarks/DELETE_BOOKMARK';

const SET_CREATING_BOOKMARK = 'dt/bookmarks/SET_CREATING_BOOKMARK';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const deleteBookmark = (fileId, id) => ({
  type: DELETE_BOOKMARK,
  fileId,
  id,
});

export const createBookmark = (fileId, bookmark) => ({
  type: CREATE_BOOKMARK,
  fileId,
  bookmark,
});

export const updateBookmark = (fileId, bookmarkId, bookmark) => ({
  type: UPDATE_BOOKMARK,
  fileId,
  bookmarkId,
  bookmark,
});

export const creatingBookMark = (isCreating) => ({
  type: SET_CREATING_BOOKMARK,
  isCreating,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  bookmarks: {},
  loaded: false,
  isCreating: false,
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CREATING_BOOKMARK: {
      return { ...state, isCreating: action.isCreating };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        bookmarks: {},
        loaded: false,
      };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doDeleteBookmark(action) {
  const { fileId, id } = action;
  const fileObject = yield select((state) => state.files.fileObjects[fileId]);

  try {
    yield call([services.file, 'deleteBookmark'], fileId, id);
    yield put({ type: DELETE_BOOKMARK_SUCCESS, id });
    yield put(
      displayNotification(getNotification('deleteBookmark', 'success')(fileObject.filename, id))
    );
    yield put(refreshSessions());
    yield put(bookmarkDeleted(fileId, id));
  } catch (e) {
    console.error('Unable to delete bookmark: ', e);
    yield call(checkOnline);
    yield put(
      displayNotification(getNotification('deleteBookmark', 'error')(fileObject.filename, id))
    );
  }
}

function* doCreateBookmark(action) {
  const { fileId, bookmark } = action;

  try {
    const fileObject = yield select((state) => state.files.fileObjects[fileId]);
    yield put(creatingBookMark(true));
    const res = yield call([services.file, 'createBookmark'], fileId, bookmark);
    yield put(createBookmarkSuccess({ fileId, bookmark: res }));
    yield put(
      displayNotification(getNotification('createBookmark', 'success')(fileObject.filename, res.id))
    );

    yield put(refreshSessions());
    yield put(bookmarkCreated(fileId, res));
    yield delay(3000);
    yield put(creatingBookMark(false));
  } catch (e) {
    console.error('Unable to create bookmark: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createBookmark', 'error')()));
  }
}

function* doUpdateBookmark(action) {
  const { fileId, bookmarkId, bookmark } = action;

  try {
    const fileObject = yield select((state) => state.files.fileObjects[fileId]);
    yield put(creatingBookMark(true));
    const res = yield call([services.file, 'updateBookmark'], fileId, bookmarkId, bookmark);
    yield put(updateBookmarkSuccess({ fileId, bookmarkId, bookmark: res }));
    yield put(
      displayNotification(getNotification('updateBookmark', 'success')(fileObject.filename, res.id))
    );
    // yield put(receiveTags(res.tags));
    yield put(refreshSessions());
    yield delay(3000);
    yield put(creatingBookMark(false));
  } catch (e) {
    console.error('Unable to update bookmark: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateBookmark', 'error')()));
  }
}

export const sagas = [
  takeLatest(CREATE_BOOKMARK, doCreateBookmark),
  takeLatest(UPDATE_BOOKMARK, doUpdateBookmark),
  takeEvery(DELETE_BOOKMARK, doDeleteBookmark),
];
