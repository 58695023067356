import React, { useEffect, useState } from 'react';
import { Button, Spinner } from '@iq/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { setActiveComponentId } from '../../bundles/application';
import { getActiveSite } from '../../bundles/sites';
import {
  getProject,
  exportMimsProject,
  exportEcProject,
  fetchProjectAction,
} from '../../bundles/projects';
import Sidebar from '../Sidebar';
import PageHeader from '../PageHeader';
import HeaderSettings from '../PageHeader/HeaderSettings';
import ConnectivityStatus from '../PageHeader/ConnectivityStatus';
import Notifications from '../Notifications';
import FoldableTreeView from './TreeView';

const DEFAULT_DATA = {
  machModel: {
    name: 'HVDC_DEMO',
    computers: [],
  },
  mimsModel: {
    name: 'HVDC_DEMO',
    ip: '192.168.0.20',
    equipments: [],
    equipmentInstances: [],
  },
  ecModel: {
    name: 'HVDC_DEMO',
    environment: 'XTWIN',
    typeDefs: [],
    tenantId: '8a196eef-f3dd-4576-b27a-6cd4b8f1eef2',
    projectId: '7fd81089-47f0-4e52-9830-352f4da2ae76',
    edgeId: 'f3a7df2a-6389-458f-a7fb-868770d6daa2',
    moduleId: '308886e4-7e4f-4925-bd44-d72a6e6601fe',
    objInstances: [],
    deviceTwinAPI:
      'https://devicetwin.xtwin.internal.digitalenterpriseconnect.com/swagger/index.html',
    dataAccessAPI:
      'https://devicetwin.xtwin.internal.digitalenterpriseconnect.com/swagger/index.html',
    typeDefAPI: 'https://devicetwin.xtwin.internal.digitalenterpriseconnect.com/swagger/index.html',
  },
};

const BaseViewOverview = ({
  withConnectivity = true,
  isTenantAdminView = false,
  noSiteView = false,
  siteAdminView = false,
  headerChildren,
  className = '',
}) => {
  const dispatch = useDispatch();
  const site = useSelector(getActiveSite);
  const params = useParams();
  const { projectId } = params;
  const projectFromApi = useSelector((state) => getProject(state, projectId));

  useEffect(() => {
    if (projectFromApi) return;

    dispatch(fetchProjectAction(projectId));
  }, [dispatch, projectId, projectFromApi]);

  const project = {
    ...(projectFromApi || {}),
    ...DEFAULT_DATA,
  };

  const onResetComponent = () => dispatch(setActiveComponentId(null));

  const [isLoading, setIsLoading] = useState(false);

  const handleClickExportMims = () => {
    setIsLoading(true);
    setTimeout(() => {
      dispatch(exportMimsProject(project.id));
      setIsLoading(false);
    }, 2000);
  };

  const handleClickExportEc = () => {
    setIsLoading(true);
    setTimeout(() => {
      dispatch(exportEcProject(project.id));
      setIsLoading(false);
    }, 2000);
  };

  let machModel = {};
  try {
    machModel = {
      name: project.machModel.name,
      children: [
        {
          name: project.machModel.name,
          children: [
            {
              name: project.machModel.computers.$values[0].name,
              children: [
                {
                  name: project.machModel.computers.$values[0].groups.$values[0].name,
                  children: [
                    {
                      name: `name: ${project.machModel.computers.$values[0].groups.$values[0].items.$values[0].name}`,
                    },
                    {
                      name: `sampleRate: ${project.machModel.computers.$values[0].groups.$values[0].items.$values[0].sampleRate}`,
                    },
                    {
                      name: `subscriptionType: ${project.machModel.computers.$values[0].groups.$values[0].items.$values[0].subscriptionType}`,
                    },
                    {
                      name: 'modelRefInst',
                      children: [
                        {
                          name: 'ecVariableRef',
                          children: [
                            {
                              name: `name: ${project.machModel.computers.$values[0].groups.$values[0].items.$values[0].modelRefInst.ecVariableRef.name}`,
                            },
                            {
                              name: `dataType: ${project.machModel.computers.$values[0].groups.$values[0].items.$values[0].modelRefInst.ecVariableRef.dataType}`,
                            },
                            {
                              name: `description: ${project.machModel.computers.$values[0].groups.$values[0].items.$values[0].modelRefInst.ecVariableRef.description}`,
                            },
                            {
                              name: `unit: ${project.machModel.computers.$values[0].groups.$values[0].items.$values[0].modelRefInst.ecVariableRef.unit}`,
                            },
                          ],
                        },
                        {
                          name: 'mimsPropertyRef',
                          children: [
                            {
                              name: `name: ${project.machModel.computers.$values[0].groups.$values[0].items.$values[0].modelRefInst.ecVariableRef.name}`,
                            },
                            {
                              name: `dataType: ${project.machModel.computers.$values[0].groups.$values[0].items.$values[0].modelRefInst.ecVariableRef.dataType}`,
                            },
                            {
                              name: `description: ${project.machModel.computers.$values[0].groups.$values[0].items.$values[0].modelRefInst.ecVariableRef.description}`,
                            },
                            {
                              name: `itemDesignation: ${project.machModel.computers.$values[0].groups.$values[0].items.$values[0].modelRefInst.ecVariableRef.itemDesignation}`,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };

    // TODO: loop through computers $values
  } catch (e) {
    console.log(e);
  }

  let mimsModel;
  try {
    mimsModel = {
      name: project.mimsModel.name,
      children: [
        {
          name: `${project.mimsModel.name} (${project.mimsModel.ip})`,
          children: [],
        },
      ],
    };
  } catch {
    mimsModel = {};
  }

  const ecModel = {};

  if (!project) return null;

  return (
    <>
      <Sidebar
        projectId={projectId}
        ecProject={project?.ecModel}
      />
      <section className={`base-view ${className}`}>
        <PageHeader
          site={!isTenantAdminView && site}
          title={{
            type: 'string',
            value: project.name,
          }}
          singlePage={true}
          isTenantAdminView={isTenantAdminView}
          dispatchResetComponent={onResetComponent}
        >
          {headerChildren}
          {withConnectivity && !noSiteView && !siteAdminView && (
            <ConnectivityStatus siteId={site.id} />
          )}
          <Notifications />

          <HeaderSettings
            site={site}
            noSiteView={noSiteView}
          />
        </PageHeader>
        <div className="panel">
          <div className="panel-header">
            <span className="iot-class">Overview</span>
            <div className="panel-header-button">
              {isLoading && <Spinner />}
              <Button onClick={handleClickExportMims}>Export MIMS</Button>
              <Button onClick={handleClickExportEc}>Export EC</Button>
            </div>
          </div>

          <div className="data-container">
            <div className="column mach-data">
              <p className="title">MACH</p>
              <FoldableTreeView data={machModel} />
            </div>
            <div className="column mims-data">
              <p className="title">MIMS</p>
              <FoldableTreeView data={mimsModel} />
            </div>
            <div className="column dec-data">
              <p className="title">EC</p>
              <FoldableTreeView data={ecModel} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BaseViewOverview;
