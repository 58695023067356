import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { Button } from '@iq/react-components';
import {
  getProjects,
  resetNewlyCreatedProjectId,
  selectNewlyCreatedProjectId,
} from '../../bundles/projects';
import { getSites } from '../../bundles/sites';
import { getLayouts } from '../../bundles/layouts';
import FileUpload from '../FileUpload';
import ProjectCreateModal from './components/ProjectCreateModal/ProjectCreateModal';

const ProjectCreateUpdateView = React.memo(
  ({ siteParams = {}, title, onSave, showButtonDownload = true, createUpdateButtonText }) => {
    const { integrationInfo = 'Identiq' } = siteParams;
    const { isIntegration = false } = integrationInfo;
    const [displayAddSiteModal, setDisplayAddSiteModal] = useState(isIntegration);

    const [zipFile, setZipFile] = useState('');
    const [zipFileName, setZipFileName] = useState('');
    const newlyCreatedProjectId = useSelector(selectNewlyCreatedProjectId);
    const [redirect, setRedirect] = useState();

    const dispatch = useDispatch();
    const layouts = useSelector(getLayouts);
    const sites = useSelector(getSites);
    const projects = useSelector(getProjects);
    const { name } = useParams();
    const matchingProject = projects.find((p) => p.name === name);

    const layoutOptions = useMemo(
      () =>
        Object.keys(layouts)
          .filter((siteId) => sites.findIndex((s) => s.id === siteId && s.isTemplate) >= 0)
          .map((siteId) => ({
            key: siteId,
            siteName: sites.find(({ id }) => id === siteId)?.name,
          })),
      [sites, layouts]
    );

    const transformErrors = (errors) => {
      return errors.map((error) => {
        /* eslint-disable no-return-assign, no-param-reassign */
        switch (error.name) {
          case 'format':
            if (error.property === '.ip')
              error.message = 'Should be in format "ipv4" ex. "123.456.7.89"';
            if (['.moduleId', '.tenantId', '.edgeId', '.projectId'].includes(error.property))
              error.message =
                'Should be in format "uuid" ex. "308886e4-7e4f-4925-bd44-d72a6e6601fe" ';
            break;
          case 'pattern':
            if (['.ecTypeDefAPI', '.ecDataAccessAPI', '.ecDeviceTwinAPI'].includes(error.property))
              error.message =
                'Should be a url ex. https://devicetwin.xtwin.internal.digitalenterpriseconnect.com/swagger/index.html';
            if (error.property === '.sampleRate')
              error.message = 'Should be a number between 1000 and 65534';
            break;
          case 'required':
            error.message = `Enter ${error.property.charAt(0).toUpperCase() + error.property.slice(1)}`;
            break;

          default:
        }
        /* eslint-enable no-return-assign, no-param-reassign */
        return error;
      });
    };
    const onClose = useCallback(() => {
      setDisplayAddSiteModal(false);
      setZipFile('');
      setZipFileName('');
    }, []);

    const handleCreateProject = (project) => {
      const projectData = {
        ...project,
        sampleRate: project.sampleRate ? project.sampleRate.toString() : null,
        hasEdge: project.deliveryType !== 'MIMS',
        fileData: zipFile,
      };

      dispatch(onSave(projectData));
    };

    const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(new Error(error));
        };
      });
    };

    const onDrop = useCallback(async (files) => {
      const file = files[0];
      const base64 = await convertBase64(file);
      setZipFile(base64.split('base64,')[1]);
      setZipFileName(file.name);
    }, []);

    const fileUrl = '/files/MasterExcelsheet.xlsx';

    useEffect(() => {
      if (!newlyCreatedProjectId) return;
      const id = newlyCreatedProjectId;

      dispatch(resetNewlyCreatedProjectId());
      setRedirect(
        <Redirect
          push={true}
          to={`/mach/${id}/`}
        />
      );
    }, [newlyCreatedProjectId]);

    if (redirect) {
      return redirect;
    }

    return (
      <div className="site-create-view-component">
        <a href={fileUrl}>
          {showButtonDownload && (
            <Button
              type="button"
              activity="primary"
              style={{ marginRight: '0.5rem' }}
            >
              Download config
            </Button>
          )}
        </a>

        <Button
          type="button"
          activity="primary"
          onClick={() => setDisplayAddSiteModal(true)}
        >
          {createUpdateButtonText || '+ Add Project'}
        </Button>

        {displayAddSiteModal && (
          <ProjectCreateModal
            title={title}
            projectData={matchingProject}
            siteParams={siteParams}
            layouts={layoutOptions}
            onSubmit={handleCreateProject}
            transformErrors={transformErrors}
            onCloseModal={onClose}
          >
            <FileUpload
              fileName={zipFileName}
              onDrop={onDrop}
            />
          </ProjectCreateModal>
        )}
      </div>
    );
  }
);

export default ProjectCreateUpdateView;
