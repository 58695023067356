/* eslint-disable default-param-last */
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import {
  getAllProjects as getAllProjectsApi,
  createProject as createProjectApi,
  deleteProject as deleteProjectApi,
  exportMimsProject as exportMimsProjectApi,
  exportEcProject as exportEcProjectApi,
  fetchProject,
} from '../services';
import { displayNotification, checkOnline } from './notifications';
import getNotification from './notification-defaults';
import { CLEAR_SITE_DATA } from './application';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const GET_PROJECTS = 'dt/projects/GET_PROJECTS';
export const RECEIVE_PROJECTS = 'dt/projects/RECEIVE_PROJECTS';
export const CREATE_PROJECT = 'dt/projects/CREATE_PROJECT';
export const UPDATE_PROJECT = 'dt/projects/UPDATE_PROJECT';
export const DELETE_PROJECT = 'dt/projects/DELETE_PROJECT';
export const EXPORT_MIMS_PROJECT = 'dt/projects/EXPORT_MIMS_PROJECT';
export const EXPORT_EC_PROJECT = 'dt/projects/EXPORT_EC_PROJECT';
export const DOWNLOAD_ZIP_PROJECT = 'dt/projects/DOWNLOAD_ZIP_PROJECT';
export const FETCH_PROJECT = 'dt/projects/FETCH_PROJECT';
export const FETCH_PROJECT_SUCCESS = 'dt/projects/FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAILURE = 'dt/projects/FETCH_PROJECT_FAILURE';
export const SET_NEWLY_CREATED_PROJECT_ID = 'dt/projects/SET_NEWLY_CREATED_PROJECT_ID';
export const RESET_NEWLY_CREATED_PROJECT_ID = 'dt/projects/SET_NEWLY_CREATED_PROJECT_ID';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const setNewlyCreatedProjectId = (id) => ({
  type: SET_NEWLY_CREATED_PROJECT_ID,
  id,
});

export const resetNewlyCreatedProjectId = () => ({
  type: RESET_NEWLY_CREATED_PROJECT_ID,
});

export const requestProjects = (callback) => ({
  type: GET_PROJECTS,
  callback,
});

export const updateProject = (project) => ({
  type: UPDATE_PROJECT,
  project,
});

export const deleteProject = (projectId, projectName) => ({
  type: DELETE_PROJECT,
  projectId,
  projectName,
});

export const receiveProjects = (projects) => ({
  type: RECEIVE_PROJECTS,
  projects,
});

export const createProject = (project) => ({
  type: CREATE_PROJECT,
  project,
});

export const exportMimsProject = (id) => ({
  type: EXPORT_MIMS_PROJECT,
  id,
});

export const exportEcProject = (id) => ({
  type: EXPORT_EC_PROJECT,
  id,
});

export const fetchProjectSuccess = (data) => ({
  type: FETCH_PROJECT_SUCCESS,
  payload: data,
});

export const fetchProjectFailure = (error) => ({
  type: FETCH_PROJECT_FAILURE,
  payload: error,
});

export const fetchProjectAction = (id) => ({
  type: FETCH_PROJECT,
  payload: id,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  projects: [],
  projectMap: {},
  newlyCreatedProjectId: null,
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_PROJECTS: {
      const { projects } = action;

      return { ...state, projects };
    }
    case SET_NEWLY_CREATED_PROJECT_ID: {
      return { ...state, newlyCreatedProjectId: action.id };
    }
    case RESET_NEWLY_CREATED_PROJECT_ID: {
      return { ...state, newlyCreatedProjectId: null };
    }
    case FETCH_PROJECT_SUCCESS: {
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
          [action.payload.id]: action.payload,
        },
      };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return { ...state, projects: [] };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getProjects = (state) => state.projects.projects;

export const getProjectsMap = (state) => state.projects.projectMap;

export const getProject = createSelector(
  getProjectsMap,
  (_, projectId) => projectId,
  (projectsMap, projectId) => projectsMap[projectId]
);

export const selectNewlyCreatedProjectId = (state) => state.projects.newlyCreatedProjectId;

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

export function* fetchProjectSaga({ payload: id }) {
  try {
    const data = yield fetchProject(id);
    yield put(fetchProjectSuccess(data));
  } catch (error) {
    yield put(fetchProjectFailure(error));
  }
}

export function* doRequestProjects() {
  try {
    const projects = yield call(getAllProjectsApi);
    projects.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    const oldProjects = yield select(getProjects);
    if (JSON.stringify(oldProjects) !== JSON.stringify(projects)) {
      yield put(receiveProjects(projects));
    }
  } catch (e) {
    yield console.error('Unable to fetch projects: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getProjects', 'error')()));
  }
}

export function* doCreateProject({ project }) {
  try {
    const newProject = yield createProjectApi(project);
    yield put(setNewlyCreatedProjectId(newProject.id));
    yield call(doRequestProjects);
    yield put(displayNotification(getNotification('createProject', 'success')(project.name)));
  } catch (e) {
    console.error('Unable to create project: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createProject', 'error')()));
  }
}

export function* doUpdateProject({ project }) {
  try {
    yield deleteProjectApi(project.id);
    yield createProjectApi(project);
    yield call(doRequestProjects);
    yield put(displayNotification(getNotification('updateProject', 'success')(project.name)));
  } catch (e) {
    console.error('Unable to create project: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateProject', 'error')()));
  }
}

function* doDeleteProject(action) {
  const { projectId, projectName } = action;
  try {
    yield call(deleteProjectApi, projectId);
    yield call(doRequestProjects);
    yield put(displayNotification(getNotification('deleteProject', 'success')(projectName)));
  } catch (e) {
    console.error('Unable to delete project: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteProjects', 'error')(projectName)));
  }
}

function* doExportMimsProject({ id }) {
  try {
    yield exportMimsProjectApi(id);
    yield put(displayNotification(getNotification('exportProjectData', 'success')()));
  } catch (e) {
    console.error('Unable to export mims project: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('exportProjectData', 'error')()));
  }
}

function* doExportEcProject({ id }) {
  try {
    yield exportEcProjectApi(id);
    yield put(displayNotification(getNotification('exportProjectData', 'success')()));
  } catch (e) {
    console.error('Unable to export ec project: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('exportProjectData', 'error')()));
  }
}

export const sagas = [
  takeLatest(GET_PROJECTS, doRequestProjects),
  takeLatest(UPDATE_PROJECT, doUpdateProject),
  takeLatest(DELETE_PROJECT, doDeleteProject),
  takeLatest(CREATE_PROJECT, doCreateProject),
  takeLatest(EXPORT_MIMS_PROJECT, doExportMimsProject),
  takeLatest(EXPORT_EC_PROJECT, doExportEcProject),
  takeLatest(FETCH_PROJECT, fetchProjectSaga),
];
