import React, { useEffect, useRef, useState } from 'react'; // eslint-disable-line no-unused-vars
import ReactDOM from 'react-dom';
import { Icon } from '@iq/react-components';

import { useBackgroundFreeze, useForceUpdate, useParentOnlyClickHandler } from '../../utils';

const modalRoot = document.getElementById('global-modal-container');

const SimpleModal = ({
  children,
  onClose: defaultOnClose,
  headerLeft,
  headerRight,
  noHeader = '',
  padding = '',
  title,
  subtitle,
  className = '',
  containerClass = '',
  size = 's',
  overlayCanClose = true,
}) => {
  const [didRender, setDidRender] = useState(false);
  const containerRef = useRef();
  const overlayRef = useRef();
  const forceUpdate = useForceUpdate();

  const onClose =
    typeof defaultOnClose !== 'function'
      ? defaultOnClose
      : (event) => {
          if (event.target === event.currentTarget) {
            defaultOnClose(event);
          }
        };

  const keyListener = (e) => {
    if (e.keyCode === 27) {
      const topModal = document.querySelector('#global-modal-container > div:last-child');
      if (topModal === containerRef.current) {
        defaultOnClose(e);
      }
    }
  };

  // prevents accordion list items' edit modals from toggling accordion
  const clickHandler = (e) => e.stopPropagation();

  useParentOnlyClickHandler(onClose, overlayRef.current, overlayCanClose);
  useBackgroundFreeze();

  useEffect(() => {
    document.addEventListener('keydown', keyListener);
    setDidRender(true);
    containerRef.current.scrollTo(0, 0);
    return () => {
      document.removeEventListener('keydown', keyListener);
    };
  }, []);

  if (!didRender) setTimeout(forceUpdate);

  return ReactDOM.createPortal(
    <div
      className={`simple-modal-container ${containerClass}`}
      onClick={clickHandler}
      ref={containerRef}
    >
      <div
        ref={overlayRef}
        className="simple-modal-overlay"
        style={{ cursor: overlayCanClose ? 'pointer' : 'default' }}
      >
        <div
          className={`simple-modal-wrapper ${className} size-${size} ${noHeader} padding-${padding}`}
        >
          {(typeof title !== 'undefined' ||
            typeof defaultOnClose !== 'undefined' ||
            typeof subtitle !== 'undefined') &&
            noHeader !== 'noHeader' && (
              <div className="simple-modal-header">
                {(typeof title !== 'undefined' ||
                  typeof defaultOnClose !== 'undefined' ||
                  typeof headerLeft !== 'undefined' ||
                  typeof headerRight !== 'undefined') && (
                  <div className="simple-modal-main-header">
                    <div className="simple-modal-main-header__left">
                      {typeof headerLeft !== 'undefined' && (
                        <div className="header-left">{headerLeft}</div>
                      )}
                      {typeof title !== 'undefined' && <div className="title">{title}</div>}
                    </div>
                    <div className="simple-modal-main-header__right">
                      {typeof headerRight !== 'undefined' && (
                        <div className="header-right">{headerRight}</div>
                      )}
                      {typeof defaultOnClose !== 'undefined' && (
                        <div className="close">
                          <Icon
                            icon="abb-close"
                            onClick={defaultOnClose}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {typeof subtitle !== 'undefined' && (
                  <div className="simple-modal-sub-header">
                    {typeof subtitle !== 'undefined' && <div className="subtitle">{subtitle}</div>}
                  </div>
                )}
              </div>
            )}
          <div className={`simple-modal-body padding-${padding}`}>{children}</div>
        </div>
      </div>
    </div>,
    modalRoot
  );
};

export default SimpleModal;
